<template>
  <v-row>
    <v-col cols="12" align-self="center">
      <v-tabs v-model="tab" grow show-arrows>
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="title in tabTitle" :key="title">
          {{ $t(title) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item v-for="(title, index) in tabTitle" :key="title">
          <v-row class="d-flex align-center mt-6 ms-3 mb-3">
            <v-col>
              <QuillEditor v-if="index == 0" editorID="editor1" :html.sync="temp_content_en"></QuillEditor>
              <QuillEditor v-else-if="index == 1" editorID="editor2" :html.sync="temp_content_zh"></QuillEditor>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-row>
</template>

<script>
import { globalFunc } from '@/global'
import QuillEditor from '@/components/QuillEditor'

export default {
  name: 'EditorTab',
  mixins: [globalFunc],
  components: { QuillEditor },
  props: ['content', 'index'],
  data: () => ({
    tab: null,
    type: ['en', 'zh'],
    tabTitle: ['general.en', 'general.zh'],
    temp_content_zh: '',
    temp_content_en: '',
  }),
  watch: {
    temp_content_zh: function (newVal, oldVal) {
      if (this.index != undefined) {
        this.$emit('getContent', newVal, 'zh', this.index)
      } else {
        this.$emit('getContent', newVal, 'zh')
      }
    },
    temp_content_en: function (newVal, oldVal) {
      if (this.index != undefined) {
        this.$emit('getContent', newVal, 'en', this.index)
      } else {
        this.$emit('getContent', newVal, 'en')
      }
    },
    content: {
      handler(data) {
        this.temp_content_zh = data.zh
        this.temp_content_en = data.en
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
