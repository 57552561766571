<template>
  <v-form ref="form">
    <v-row class="mb-4">
      <v-col>
        <h2 v-if="isAddNews">{{ $t('news.add_news') }}</h2>
        <h2 v-else>{{ $t('news.news_detail') }}</h2>
      </v-col>
    </v-row>
    <v-card elevation="0" class="px-6 py-4 mb-8">
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-menu
            v-model="date_modal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="news_date"
                :label="$t('news.news_date') + '*'"
                :append-icon="calendar_icon"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[FormValidator.required]"
                hide-details="auto"
              ></v-text-field>
            </template>
            <v-date-picker v-model="news_date" @input="date_modal = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12" v-if="!isAddNews">
          <v-select
            :label="$t('worker.status')"
            :items="status_list"
            v-model="news_data.status"
            item-text="item-text"
            item-value="item-value"
            hide-details
            outlined
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            type="text"
            :label="$t('news.title_en') + '*'"
            outlined
            hide-details
            v-model="news_data.title.en"
            :rules="[FormValidator.required]"
          ></v-text-field>
        </v-col>
        <v-col xl="6" lg="6" md="6" sm="6" cols="12">
          <v-text-field
            v-model="news_data.title.zh"
            type="text"
            :label="$t('news.title_zh')"
            outlined
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h3>{{ $t('news.news_content') }}*</h3>
        </v-col>
      </v-row>
      <EditorTab v-on:getContent="getContent" :content="news_data.content" />
      <v-row>
        <v-col>
          <h3>{{ $t('news.image') }}*</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center mb-0">
          <v-file-input
            id="news_image"
            v-show="false"
            accept="image/*"
            hide-details="auto"
            outlined
            @change="getUploadFile($event)"
          ></v-file-input>
          <v-text-field
            type="text"
            outlined
            readonly
            hide-details="auto"
            v-model="news_data.banner.file"
            :rules="[FormValidator.required]"
            :label="$t('general.select_image')"
          >
          </v-text-field>
          <v-btn color="primary" class="ml-4" small :loading="submitLoading" @click="selectFile('news_image')">
            {{ $t('general.upload') }}</v-btn
          >
          <v-btn
            class="ml-4"
            color="info"
            small
            :disabled="news_data.banner.disable_preview"
            @click="previewFile(news_data.banner.file)"
          >
            {{ $t('general.view_detail') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row>
      <v-col>
        <div class="d-flex align-center">
          <v-btn color="primary" @click="saveNews" :loading="submitLoading">{{ $t('general.save') }}</v-btn>
          <v-btn color="error" v-if="!isAddNews" class="ml-8" @click="removeData" :loading="submitLoading">{{
            $t('general.delete')
          }}</v-btn>
        </div>
      </v-col>
    </v-row>
    <Dialog :dialog="dialog.open" :text="dialog.text" :isDeleteDialog="dialog.isDelete" v-on:close="deleteEvent">
    </Dialog>
  </v-form>
</template>

<script>
import { FormValidator, globalFunc, MEDIA_PATH } from '@/global'
import Dialog from '@/components/Dialog'
import EditorTab from '@/components/EditorTab.vue'
import { mdiCalendar } from '@mdi/js'
import { getData, postData } from '@/api'

export default {
  name: 'NewsDetail',
  mixins: [FormValidator, globalFunc],
  components: {
    Dialog,
    EditorTab,
  },
  data: () => ({
    FormValidator: FormValidator,
    calendar_icon: mdiCalendar,
    submitLoading: false,
    dialog: {
      isDelete: true,
      open: false,
      text: '',
    },
    isAddNews: true,
    date_modal: false,
    news_date: '',
    news_data: {
      title: {
        zh: '',
        en: '',
      },
      content: {
        zh: '',
        en: '',
      },
      banner: {
        file: '',
        disable_preview: true,
      },
      status: '',
    },
  }),
  methods: {
    async initializeData() {
      try {
        let params = new URLSearchParams()
        params.set('get_news_by_id', JSON.stringify({ id: parseInt(this.$route.params.id, 10) }))
        let get_news_data = await getData(params)

        this.$func.log('-----Get News Data-----')
        this.$func.log(get_news_data)

        this.news_data.id = get_news_data.id
        this.news_date = get_news_data.news_date
        this.news_data.title.zh = get_news_data.title.zh
        this.news_data.title.en = get_news_data.title.en
        this.news_data.content.zh = get_news_data.content.zh
        this.news_data.content.en = get_news_data.content.en
        this.news_data.banner.file = get_news_data.banner.en
        this.news_data.banner.disable_preview = false
        this.news_data.status = get_news_data.status
      } catch (error) {
        this.$func.log(error)
        if (Array.isArray(error)) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: `${this.$t('navigation.news')} ${this.$t('error.does_not_exist')}`,
            type: 'error',
            refresh: false,
            redirect: 'News',
          })
        }
      } finally {
        this.$store.dispatch('toggleLoadingPage', false)
      }
    },
    selectFile(id) {
      document.getElementById(id).click()
    },
    async getUploadFile(file) {
      if (globalFunc.DataValid(file)) {
        if (file.type.indexOf('image/') == -1) {
          this.submitLoading = false

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: `${this.$t('error.force_upload')} ${this.$t('error.image_file')}`,
            type: 'error',
            refresh: false,
            redirect: '',
          })

          return
        }

        this.submitLoading = true
        try {
          let base64Path = await globalFunc.firstFileToBase64(file)
          let file_type = file.name.substring(file.name.lastIndexOf('.') + 1)
          let params = new URLSearchParams()
          params.set(
            'upload_file',
            JSON.stringify({
              upload_data: base64Path,
              upload_file_type: file_type,
            }),
          )

          let upload_file = await postData(params)
          this.$func.log('--- upload success ---')
          this.$func.log(upload_file)

          this.news_data.banner.file = upload_file
          this.news_data.disable_preview = false

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.upload_success'),
            type: 'success',
            refresh: false,
            redirect: '',
          })
        } catch (error) {
          this.$func.log(error)
          this.$func.log('--- upload fail ---')
          this.$func.log(data)
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.upload_fail'),
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      } else {
        this.submitLoading = false

        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: `${this.$t('error.force_upload')} ${this.$t('error.file')}`,
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    previewFile(fileName) {
      if (globalFunc.DataValid(fileName)) {
        let link = MEDIA_PATH + fileName
        window.open(link, '_blank')
      } else {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('instruction.file_not_found'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    getContent(content, type) {
      this.news_data.content[type] = content
    },
    async saveNews() {
      let form_validate = this.$refs.form.validate()
      let validate = true

      if (!globalFunc.DataValid(this.news_data.content.en)) {
        validate = false
      }

      if (!form_validate || !validate) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: this.$t('error.fill_all_required_field'),
          type: 'error',
          refresh: false,
          redirect: '',
        })
        return
      }

      try {
        this.submitLoading = true
        let send_data = {
          news_date: this.news_date,
          banner: {
            en: this.news_data.banner.file,
            zh: this.news_data.banner.file,
          },
          title: {
            en: this.news_data.title.en,
            zh: this.news_data.title.zh,
          },
          content: {
            en: this.news_data.content.en,
            zh: this.news_data.content.zh,
          },
        }

        let params = new URLSearchParams()
        if (this.isAddNews) {
          params.set('add_news', JSON.stringify(send_data))
        } else {
          send_data.id = parseInt(this.$route.params.id, 10)
          send_data.status = this.news_data.status
          params.set('update_news', JSON.stringify(send_data))
        }

        let update_news = await postData(params)

        if (this.isAddNews) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.create_success'),
            type: 'success',
            refresh: true,
            redirect: 'News',
          })
        } else {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.update_success'),
            type: 'success',
            refresh: true,
            redirect: 'News',
          })
        }
      } catch (error) {
        this.$func.log(error)
        this.submitLoading = false

        if (this.isAddNews) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.create_fail'),
            type: 'error',
            refresh: false,
            redirect: '',
          })
        } else {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.update_fail'),
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      }
    },
    removeData() {
      this.dialog.text = `${this.$t('instruction.remove_item')} ${this.$t('navigation.news')}?`
      this.dialog.open = true
    },
    async deleteEvent(action) {
      this.dialog.open = false
      if (action) {
        this.submitLoading = true
        try {
          let params = new URLSearchParams()
          params.set(
            'remove_data',
            JSON.stringify({
              id: parseInt(this.$route.params.id, 10),
              data_type: 'news_data',
            }),
          )
          let result = await getData(params)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.remove_success'),
            type: 'error',
            refresh: false,
            redirect: 'News',
          })
        } catch (error) {
          this.$func.log(error)

          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: this.$t('instruction.remove_fail'),
            type: 'error',
            refresh: false,
            redirect: '',
          })
        }
      }
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      if (globalFunc.DataValid(this.$route.params.id)) {
        this.$store.dispatch('toggleLoadingPage', true)
        this.isAddNews = false

        this.status_list = [
          { 'item-text': this.$t('status.active'), 'item-value': 'active' },
          { 'item-text': this.$t('status.inactive'), 'item-value': 'inactive' },
        ]

        this.initializeData()
      } else {
        this.isAddNews = true
      }
    }
  },
}
</script>